import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ipfs"
    }}>{`IPFS`}</h1>
    <h2 {...{
      "id": "ipfs简介"
    }}>{`IPFS简介`}</h2>
    <p>{`IPFS全称InterPlanetary File System，中文名：星际文件系统，是一个旨在创建持久且分布式存储和共享文件的网络传输协议。`}</p>
    <p>{`它是一种内容可寻址的对等超媒体分发协议。在IPFS网络中的节点将构成一个分布式文件系统。它是一个开放源代码项目，自2014年开始由Protocol Labs （协议实验室）在开源社区的帮助下发展。其最初由Juan Benet设计。`}</p>
    <p>{`IPFS是点对点的超媒体协议，可以让网络更快、更安全、更开放。它是一个面向全球的、点对点的分布式版本文件系统，试图将所有具有相同文件系统的计算设备连接在一起。`}</p>
    <p><img alt="ipfs_io" src="http://daijiale-cn.oss-cn-hangzhou.aliyuncs.com/djl-blog-pic/ipfs/ipfs_io.png" /></p>
    <p>{`IPFS—又称“星际文件系统”。简单点说，它是一个点对点的分布式文件系统（和比特币技术一样），通过底层协议，可以让存储在IPFS系统上的文件，在全世界任何一个地方快速获取，且不受防火墙的影响（无需网络代理）。`}</p>
    <p>{`我们现在所使用的互联网协议被称作——超文本协议HTTP。这种协议具有超中心化特性。`}</p>
    <p>{`也就是说，你从互联网上下载文件或者是浏览网页，一次只能从一个数据中心获取你所需要的资料。如果这个数据中心出现故障，或者被限制或是攻击，就会出现文件丢失或者网页无法打开的问题。比如你存在某云盘的资料突然无法下载，或者你想浏览的网页因为某些政策原因无法打开。`}</p>
    <p>{`而IPFS的目的就是解决这些问题。在某些方面，IPFS类似Web，你一样可以基于IPFS进行互联网地址的链接。但IPFS是去中心化的，它不存在Web的主网故障问题。所以，`}<strong parentName="p">{`IPFS完全取代掉HTTP也并非天方夜谭`}</strong>{`。`}</p>
    <h2 {...{
      "id": "ipfs与http的区别"
    }}>{`IPFS与HTTP的区别`}</h2>
    <h3 {...{
      "id": "http的四大痛点"
    }}>{`HTTP的四大痛点`}</h3>
    <p><img alt="IPFS%20is%20the%20Distributed%20Web" src="http://daijiale-cn.oss-cn-hangzhou.aliyuncs.com/djl-blog-pic/ipfs/IPFS%20is%20the%20Distributed%20Web.png" /></p>
    <blockquote>
      <p parentName="blockquote">{`HTTP效率低下，服务器成本昂贵`}</p>
    </blockquote>
    <p>{`使用HTTP协议从一台计算机服务器上一次只能下载一个文件，而不是同时从多台计算机中获取文件。通过P2P方式的视频传输可以节省带宽成本的60％。`}</p>
    <blockquote>
      <p parentName="blockquote">{`历史文件被删除`}</p>
    </blockquote>
    <p>{`网页的平均使用寿命为100天，大量的网站文件不能得以长期保存。有些重要的文件因操作不当，也有可能永远在互联网消失。`}</p>
    <blockquote>
      <p parentName="blockquote">{`中心化的网络限制了机会`}</p>
    </blockquote>
    <p>{`互联网一直是人类进步的催化器，但中心化的网络容易被控制，是对互联网良性发展的的威胁。`}</p>
    <blockquote>
      <p parentName="blockquote">{`网络应用太依赖骨干网`}</p>
    </blockquote>
    <p>{`为保证数据的可靠性，我们开发的应用程序太依赖大型的中心服务器，并通过大量的备份来保证数据的安全。`}</p>
    <p>{`HTTP协议已经用了20年的历史，从HTTP 1.0 到现在的HTTP5，网页的展示越来越美观丰富，`}<strong parentName="p">{`但它背后的Browser/Server 模式是从来没变的`}</strong>{`。`}</p>
    <h3 {...{
      "id": "ipfs区别于http痛点的特质"
    }}>{`IPFS区别于HTTP痛点的特质`}</h3>
    <blockquote>
      <p parentName="blockquote">{`互联网信息永久存储`}</p>
    </blockquote>
    <p>{`IPFS像是一个分布式存储网络（类似于SIA），任何存储在系统里的资源，包括文字、图片、声音、视频，以及网站代码，通过IPFS进行哈希运算后，都会生成唯一的地址。今后，你只要通过这个地址就可以打开它们。并且这个地址是可以被分享的。`}</p>
    <p>{`而由于加密算法的保护，该地址具备了不可篡改和删除的特性（在某种意义上，如果破解密码还是有可能被篡改或删除，但概率极低）。所以，一旦数据存储在IPFS中，它就会是永久性的。比如我们经常会遇到的某个资源删除无法访问的问题。`}</p>
    <p>{`这种情况，在IPFS上就不会发生。即便是把该站点撤销，只要存储该站点信息的网络依然存在，该网页就可以被正常访问。存储站点的分布式网络越多，它的可靠性也就越强。`}</p>
    <p>{`与SIA不同的是，IPFS存储的一般是公共信息，普通大众都可以获得的。有一种说法认为，`}<strong parentName="p">{`如果IPFS完全取代HTTP，那么此后，人类历史将会被永久保存，且不会被篡改`}</strong>{`。`}</p>
    <p>{`这也就意味着，人类所做的每一件事情都会被记录，不管是正确的、抑或是错误的。`}</p>
    <blockquote>
      <p parentName="blockquote">{`解决过度冗余问题，实现共享经济`}</p>
    </blockquote>
    <p>{`如果你喜欢某部电影，又担心电影资源丢失，通常的做法是，你会把这部电影下载在自己的电脑上。比如电影《阿凡达》，在2016年一年的下载次数就达到了1658万次，总下载数量更是惊人。那么一个无法避免的问题是：同样的一部电影被反复储存，造成了内存资源极大浪费。这就是HTTP协议的弊端。同样的资源备份的次数过多，就会造成过度冗余的问题。`}</p>
    <p>{`而IPFS的出现可以很好的解决这个问题。IPFS会把存储文件，做一次哈希计算，只字不差的两个文件哈希值相同。所以，用户只需要使用相同的哈希值，就可以访问那个文件，这个哈希值就是文件的地址。只要获取这个地址，就可以共享资源了。`}</p>
    <p>{`基于上面的永久存储特性，你再也不用担心某个电影找不到了，也不用备份，`}<strong parentName="p">{`因为全球电脑上只要有那么几个人存储着，你就能拿到它。而不是重复存储几十万份`}</strong>{`。`}</p>
    <blockquote>
      <p parentName="blockquote">{`同时基于内容寻址，而非基于域名寻址。`}</p>
    </blockquote>
    <p>{`IPFS的网络上运行着一条区块链，即用来存储互联网文件的哈希值表，每次有网络访问，即要在链上查询该内容（文件）的地址。`}</p>
    <p>{`文件（内容）具有存在的唯一性，一个文件加入了IPFS的网络，将基于计算对内容赋予一个唯一加密的哈希值。这将改变我们使用域名访问网络的习惯。`}</p>
    <p>{`提供文件的历史版本控制器（类似Git），并且让多节点使用保存不同版本的文件。`}</p>
    <blockquote>
      <p parentName="blockquote">{`节点存储激励，代币分成`}</p>
    </blockquote>
    <p>{`通过使用代币（FileCoin）的激励作用，让各节点有动力去存储数据。 Filecoin 是一个由加密货币驱动的存储网络`}<strong parentName="p">{`。矿工通过为网络提供开放的硬盘空间获得Filecoin，而用户则用 Filecoin 来支付在去中心化网络中储存加密文件的费用`}</strong>{`。`}</p>
    <h2 {...{
      "id": "ipfs工作原理"
    }}>{`IPFS工作原理`}</h2>
    <p><img alt="ipfswork" src="http://daijiale-cn.oss-cn-hangzhou.aliyuncs.com/djl-blog-pic/ipfs/ipfswork.jpg" /></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`每个文件及其中的所有块都被赋予一个称为加密散列的唯一指纹。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`IPFS通过网络删除重复具有相同哈希值的文件，通过计算是可以判断哪些文件是冗余重复的。并跟踪每个文件的版本历史记录。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`每个网络节点只存储它感兴趣的内容，以及一些索引信息，有助于弄清楚谁在存储什么。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`查找文件时，你通过文件的哈希值就可以在网络查找到储存改文件的节点，找到想要的文件。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`使用称为IPNS（去中心化命名系统），每个文件都可以被协作命名为易读的名字。通过搜索，就能很容易地找到想要查看的文件。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`从IPFS的介绍可以看出， IPFS设想的是让所有的网络终端节点不仅仅只充当 Browser或Client的角色，其实人人都可以作为这个网络的运营者，人人都可以是服务器。--------------`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      